import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import useClearFetchStore from '@/stores/useClearFetchStore';
import { API_GET_USER_DETAIL_BY_TOKEN, API_UPDATE_PROFILE } from '~/static/urls/user.js';
import { USER_ACTIVATION } from '@/utils/activity';

const useActivateUserStore = defineStore(
  'user_activation',
  () => {
    // state
    const userData = ref(null);
    const successMessage = ref(null);
    const accountError = ref(null);
    const resetToken = ref(null);

    const clearFetch = useClearFetchStore();
    const router = useRouter();

    const setResetToken = (value) => {
      resetToken.value = value;
    };

    /**
     *
     * @param {*} clear/remove token
     */
    const clearResetToken = () => {
      resetToken.value = undefined;
    };
    const updateUser = async (req, token) => {
      const url = API_UPDATE_PROFILE.replace('{reset_token}', token);
      const { data, error } = await clearFetch.clearPut(url, req, true);
      if (data.value && data.value.data && !error.value) {
        // user data updated
        successMessage.value = data.value.message;
        setResetToken(null);
        clearFetch.setLastActivity(USER_ACTIVATION);
        await router.replace('/');
      }
    };

    const setSuccess = (msg) => {
      successMessage.value = msg;
    };

    const updateUserData = (data) => {
      if (!data) {
        accountError.value = null;
        setSuccess(null);
      }
      userData.value = data;
    };

    const getUser = async (token) => {
      const url = computed(() => API_GET_USER_DETAIL_BY_TOKEN.replace('{reset_token}', token));
      const { data, error } = await clearFetch.clearGet(url.value, true);
      if (data.value && !error.value) {
        updateUserData(data.value.data);
        setResetToken(data.value.data.activationToken);
      } else {
        accountError.value = {
          status: error.value.statusCode,
          message: error.value.data.message
        };
      }
    };

    const clearActivateData = () => {
      userData.value = null;
      successMessage.value = null;
      accountError.value = null;
      resetToken.value = null;
    };

    const isRealmAdmin = computed(() => userData.value && userData.value.role_type === 1);

    const lastActivityLog = computed(() => clearFetch.lastActivityLog);

    return {
      userData,
      successMessage,
      isRealmAdmin,
      resetToken,

      accountError,
      lastActivityLog,

      updateUser,
      getUser,
      updateUserData,
      setSuccess,
      setResetToken,
      clearResetToken,
      clearActivateData
    };
  },
  {
    persist: false
  }
);

export default useActivateUserStore;
